/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"


function Seo({ description, lang, meta, title, noindex }) {

  var { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  var currentUrl = '';
  currentUrl = process.env.GATSBY_SITE_URL+pathname;

  // set no index for result pagination
  let hasPagination = false;
  if (pathname !== undefined && pathname) {
    let pageStr = pathname.split('page-');
    hasPagination = pageStr[1] || false;
    if (pageStr[0] !== undefined) {
     currentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
    }
  }

  if (!currentUrl.endsWith('/'))
        currentUrl = currentUrl + '/';

  //remove multiple slashes
  currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1");
  //set lowercase url
  currentUrl = currentUrl.toLowerCase();

  
  var fblog = {}
  
  if(pathname === "/"){
    fblog = {
      name : `facebook-domain-verification`,
      content : `aoz5o20tp6ee5kn8e4rum57w9vgbvx`
    }
    
  }

  var robots_meta = noindex? {name: `robots`,content: "noindex,nofollow"} : ""
 
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
            href: currentUrl,
            rel: "canonical"
        }
    ]}
      title={title}
      titleTemplate={defaultTitle ? null : null}
      meta={"i.prod" == process.env.GATSBY_STRAPI_GGFX_ENV ?[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        robots_meta,
        fblog
      ].concat(meta) :
      [
        {
          name:`robots`,
          content:`follow, noindex`
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `facebook-domain-verification` ,
          content: `aoz5o20tp6ee5kn8e4rum57w9vgbvx` ,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        fblog
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
